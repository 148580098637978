import { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { fromJS, is } from 'immutable';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';

import { notification } from '@unitoio/mosaic';

import { getLinkById, getLinkSyncStatus, getUserId, getSelectedOrganizationId } from '~/reducers';
import * as containerActions from '~/actions/containers';
import * as draftActions from '~/actions/drafts';
import * as linkActions from '~/actions/links';
import * as providerActions from '~/actions/providers';
import * as websocketActions from '~/actions/websocket';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as websocketTypes from '~/consts/websocket';
import { useLogger } from '~/hooks/useLogger';

import * as formUtils from '../utils/form';
import { useGetFormData } from './useGetFormData';
import { useSetContainersErrors } from './useSetContainersErrors';

export function useAutoSaveDraft(control, loadingState) {
  const formData = useWatch({ control });
  const formDataRef = useRef(formData);
  const lastSaveCallRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { _id: linkId, state: linkState } = formData;
    const isDraft = linkState === linkTypes.LINK_STATES.DRAFT;
    const hasFormChanged = !is(fromJS(formDataRef.current), fromJS(formData));
    const hasLinkLoaded =
      loadingState !== formUtils.loadingStates.INITIAL && loadingState !== formUtils.loadingStates.LOADING;
    const shouldSave = linkId && isDraft && hasLinkLoaded && hasFormChanged;

    const debouncedSave = debounce(
      () => {
        dispatch(linkActions.saveLinkV2(formData)).catch((err) => {
          const { code } = err;

          if (code === 404) {
            notification.error({
              message: 'Flow not found',
              description: 'Unito was not able to find this flow.',
              placement: 'top',
              duration: 4,
            });
            history.replace(routes.ABSOLUTE_PATHS.DASHBOARD);
          }

          if (code === 403) {
            notification.error({
              message: 'Unauthorized',
              description: 'Your are not authorized to edit this flow.',
              placement: 'top',
              duration: 4,
            });
            history.replace(routes.ABSOLUTE_PATHS.DASHBOARD);
          }
        });
      },
      500,
      { leading: false, trailing: true },
    );

    if (shouldSave) {
      if (lastSaveCallRef.current) {
        lastSaveCallRef.current.cancel();
      }

      debouncedSave();
      formDataRef.current = formData;
      lastSaveCallRef.current = debouncedSave;
    }
  }, [dispatch, formData, history, loadingState]);
}

export function useCreateDraft(formMethods, setLoadingState) {
  const {
    formState: { isDirty },
    reset,
    watch,
  } = formMethods;
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { mode } = useParams();
  const isAdd = mode === 'add';

  useEffect(() => {
    async function createDraft() {
      const formData = watch();
      setLoadingState(formUtils.loadingStates.SAVING);
      try {
        const { link } = await dispatch(draftActions.createDraft(formData));
        const updatedLink = formUtils.linkPayloadToFlowBuilderFormData(fromJS(link));
        reset(updatedLink, { keepValues: false, keepDirty: false, keepDirtyValues: false, keepErrors: true });

        replace(`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${link._id}/${routes.FLOW_BUILDER_PAGES.TOOL_SELECTION}`);
      } catch (err) {
        notification.error({
          message: 'A problem occurred while saving this flow.',
          description: 'Try again. If this error persists, please contact support.',
          placement: 'top',
        });
      } finally {
        setLoadingState(formUtils.loadingStates.SAVED);
      }
    }

    if (isAdd && isDirty) {
      createDraft();
    }
  }, [dispatch, isAdd, isDirty, setLoadingState, reset, replace, watch]);
}

export async function fetchLinkContainers(dispatch, link, setContainerErrors) {
  const [containerA, containerB] = await Promise.allSettled(
    ['A', 'B'].map(async (side) => {
      const { providerIdentityId, containerId, containerType, itemType, parentContainerId } = link[side];
      if (!providerIdentityId || !containerId || !containerType || !itemType) {
        return Promise.resolve();
      }
      return dispatch(
        containerActions.getContainerById({
          providerIdentityId,
          containerId,
          containerType,
          itemType,
          parentContainerId,
          options: { displayError: false },
        }),
      );
    }),
  );

  setContainerErrors({
    A: containerA.status === 'rejected' ? containerA.reason : null,
    B: containerB.status === 'rejected' ? containerB.reason : null,
  });
}

/**
 * Custom hook for fetching a link.
 * Only fetch the link when the loading state is INITIAL and the link ID is defined (meaning we've just entered the flow builder).
 * It should not be called if the linkId is defined on a duplicate route
 *
 * @param {string} loadingState - The current loading state.
 * @param {string} linkId - The ID of the link to fetch.
 * @param {function} setLoadingState - The function to set the loading state.
 * @param {function} dispatch - The dispatch function from the Redux store.
 */
export async function useFetchLink({
  linkId,
  loadingState,
  setLoadingState,
  isDuplicate = false,
  reportException,
  formMethods,
}) {
  const dispatch = useDispatch();
  const { clearErrors, reset, setError } = formMethods;
  const linkHasSyncStatus = !useSelector((state) => getLinkSyncStatus(state, linkId)).isEmpty();
  const userId = useSelector((state) => getUserId(state));
  const organizationId = useSelector(getSelectedOrganizationId);
  const setContainerErrors = useSetContainersErrors(setError, clearErrors);

  // re-subcribe to the link if the initial subscription in getLink didn't succeed, so we don't re-fetch the link
  useEffect(() => {
    if (loadingState === formUtils.loadingStates.LOADED && !linkHasSyncStatus) {
      websocketActions.subscribe({
        currentPage: websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_ACTIVITY,
        organizationId,
        userId,
        linkIds: [linkId],
      });
    }
  }, [linkHasSyncStatus, linkId, loadingState, organizationId, setLoadingState, userId]);

  useEffect(() => {
    if (loadingState !== formUtils.loadingStates.INITIAL || !linkId) {
      return;
    }

    setLoadingState(formUtils.loadingStates.LOADING);
    const fetchLink = async () => {
      try {
        const response = await dispatch(linkActions.getLink(linkId, {}, { displayError: false }));
        const { link } = response;
        const updatedLink = formUtils.linkPayloadToFlowBuilderFormData(fromJS(link));
        reset(updatedLink, { keepValues: false, keepDirty: false, keepDirtyValues: false, keepErrors: true });
        await fetchLinkContainers(dispatch, updatedLink, setContainerErrors);
        setLoadingState(formUtils.loadingStates.LOADED);
      } catch (err) {
        setLoadingState(formUtils.loadingStates.ERROR);
        reportException(err);
      }
    };

    fetchLink();
  }, [dispatch, linkId, loadingState, reportException, setContainerErrors, setLoadingState, isDuplicate, reset]);
}

const resetFormData = (formMethods, currentLink) => {
  const { reset, getValues } = formMethods;
  const values = getValues();
  const updatedLink = formUtils.linkPayloadToFlowBuilderFormData(currentLink, values);
  // Keep the values that are not in the link payload but part of the form data as hidden fields
  reset({ ...values, ...updatedLink });
};

export function useResetFormOnLinkIdChange(linkId, currentLink, formMethods, loadingState) {
  // resets the form if the linkId match params changes
  useEffect(() => {
    if (linkId && loadingState && loadingState !== formUtils.loadingStates.INITIAL) {
      resetFormData(formMethods, currentLink);
    }
    // disabling because we do NOT want this to trigger every time the loading state changes
    // we only want it to trigger when linkId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkId]);
}

/**
 * A custom hook that handles the form submission in the FlowBuilder component.
 * The hook is responsible for dispatching the correct action based on the link state.
 * In addition, the hook is responsible for redirecting the user to the newly created draft.
 * Request aborting is also handled by this hook to prevent loading state flickering when multiple requests are dispatched.
 */
export function useOnSubmit({
  formMethods,
  history,
  linkId,
  loadingState,
  reportException,
  setLoadingState,
  workflowId,
}) {
  const dispatch = useDispatch();
  const { replace } = history;
  const { reset } = formMethods;
  const { mode } = useParams();

  const isDuplicate = mode === 'duplicate';

  return useCallback(
    async (formValues, { convertDraft = false, redirectPage = null } = {}) => {
      setLoadingState(formUtils.loadingStates.SAVING);

      if (loadingState === formUtils.loadingStates.SAVING) {
        return;
      }

      try {
        const actionToDispatch = convertDraft ? draftActions.convertDraft : linkActions.saveLink;
        const response = await dispatch(actionToDispatch({ ...formValues, _id: formValues._id ?? linkId }));
        const { link } = response;

        const updatedLink = formUtils.linkPayloadToFlowBuilderFormData(fromJS(link), formValues);
        reset(
          { ...formValues, ...updatedLink },
          { keepValues: false, keepDirty: false, keepDirtyValues: false, keepErrors: true },
        );

        setLoadingState(formUtils.loadingStates.SAVED);

        if (link) {
          const baseRedirectUrl = workflowId
            ? `${routes.ABSOLUTE_PATHS.FLOW_BUILDER_WORKFLOW}/${workflowId}/${link._id}`
            : `${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${link._id}`;

          // If there was no previous linkId it means that we just crated a draft and need do redirect to the newly created draft
          const page = !linkId ? routes.FLOW_BUILDER_PAGES.TOOL_SELECTION : redirectPage;
          if (page) {
            replace(`${baseRedirectUrl}/${page}`);
          }
        }
      } catch (err) {
        reportException(err);
        setLoadingState(formUtils.loadingStates.ERROR);
        throw err;
      }
    },
    [setLoadingState, loadingState, linkId, dispatch, reset, workflowId, replace, reportException, isDuplicate],
  );
}

/**
 * Custom hook to update provider capabilities form item, if the side information has changed.
 */
export function useUpdateProviderCapabilitiesForItem({ formData, isSuspended, linkId, formMethods, side }) {
  const dispatch = useDispatch();
  const { providerName, providerIdentityId, containerId, itemType } = formData[side];
  const { setError, clearErrors } = formMethods;
  const setFormErrors = useSetContainersErrors(setError, clearErrors);

  // We might have a loop caused by suspended links whenever we load the flowbuilder
  // These are the only 2 calls we do as soon as we load the guide for a specific link
  // so we're preventing calling them for now if a link is suspended since anyways users won't
  // be able to edit the link
  useEffect(() => {
    const fetchCapabilities = async () => {
      if (providerName && providerIdentityId && !isSuspended) {
        try {
          await dispatch(providerActions.getProviderCapabilities(providerName, providerIdentityId));
        } catch (err) {
          setFormErrors({ [side]: err });
        }
      }
    };
    fetchCapabilities();
  }, [dispatch, providerIdentityId, providerName, isSuspended, side, setFormErrors]);

  useEffect(() => {
    const fetchCapabilitiesForItem = async () => {
      if (providerName && providerIdentityId && containerId && itemType && !isSuspended) {
        try {
          await dispatch(
            providerActions.getProviderCapabilitiesForItem(
              providerName,
              providerIdentityId,
              containerId,
              itemType,
              linkId,
            ),
          );
        } catch (err) {
          setFormErrors({ [side]: err });
        }
      }
    };
    fetchCapabilitiesForItem();
  }, [containerId, dispatch, itemType, providerIdentityId, providerName, isSuspended, linkId, side, setFormErrors]);
}

export function deepCloneAndOmit(obj, paths) {
  // Deep clone the object
  const clonedObj = JSON.parse(JSON.stringify(obj));

  // Iterate over each path
  paths.forEach((path) => {
    // Split the path and reduce it to get the nested object
    const pathParts = path.split('.');
    const lastKey = pathParts.pop();
    const updatedNestedObj = pathParts.reduce((nested, key) => {
      const newNested = nested[key] || {};
      return newNested;
    }, clonedObj);

    // Delete the key from the updated nested object
    delete updatedNestedObj[lastKey];
  });

  return clonedObj;
}
/**
 * Custom hook for the FlowBuilder component to handle form data and submission.
 * Initializes the form data based on the link ID and the query params.
 *
 * - Retrieves the link from the Redux store based on the link ID and populates the form with the link data after the link is fetched or updated.
 * - Handles the form submission and dispatches the correct action based on the link state (create draft, update draft, convert draft, update link).
 * - When in draft state, the form is auto-saved every time the form data is dirty.
 * - When the form is successfully submitted, the form is reset and the errors are cleared.
 * - When the form is successfully submitted a draft is created and the user is redirected to the newly created draft.
 * - Updates the provider capabilities when containers information are changed, to retrieve the new capabilities dynamically.
 * - Handles duplicating an existing flow
 * - Handles the loading state of the form data
 *
 */
export function useFlowBuilderGetForm() {
  const {
    params: { linkId, workflowId },
  } = useRouteMatch();
  const shouldDuplicate = !!useRouteMatch(routes.getDuplicateFlowBuilderRoute(linkId));
  const history = useHistory();
  const [loadingState, setLoadingState] = useState(
    !linkId ? formUtils.loadingStates.NEW : formUtils.loadingStates.INITIAL,
  );
  const { reportException } = useLogger();
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const defaultValues = useGetFormData(linkId, currentLink, shouldDuplicate);
  const formMethods = useForm({ defaultValues });

  const values = formMethods.getValues();

  // We can't rely on the native formState.isDirty to determine if there are changes to be saved
  // It is working intermittently and doesn't always reset properly if you revert a field to its original value
  // (e.g.: flow name) and also doesn't consistently work when adding and removing rules.
  // see react-hook-form threads here:
  // https://github.com/react-hook-form/react-hook-form/issues/3213 (issue was clsoed by a bot but looks like the issue
  // is still happening)
  // and this thread about the issues with `remove` from `useFieldArray`:
  // https://github.com/react-hook-form/react-hook-form/issues/9932
  // Instead we're manually resorting to checking changes between the form and default values
  // except we now need to exclude certain fields from the comparison because they're only used for UI
  // purposes and not saved in the DB
  const defaultValuesWithOmmitedField = deepCloneAndOmit(defaultValues, [
    'linkSettingsLastModifiedOn',
    'lastSyncRequest',
  ]);

  const valuesWithOmmitedField = deepCloneAndOmit(values, ['linkSettingsLastModifiedOn', 'lastSyncRequest']);

  const hasChanged =
    formMethods.formState.isDirty && !is(fromJS(defaultValuesWithOmmitedField), fromJS(valuesWithOmmitedField));

  const onSubmit = useOnSubmit({
    formMethods,
    shouldDuplicate,
    loadingState,
    setLoadingState,
    linkId: currentLink?.get('_id', linkId),
    reportException,
    workflowId,
    history,
  });

  useCreateDraft(formMethods, setLoadingState);
  useAutoSaveDraft(formMethods.control, loadingState);

  // Fetch the link to populate the form
  useFetchLink({ linkId, loadingState, setLoadingState, isDuplicate: shouldDuplicate, reportException, formMethods });

  // Reset the form when the linkId changes
  useResetFormOnLinkIdChange(linkId, currentLink, formMethods, loadingState);

  // Since the introduction of modern integrations, we need to fetch the provider capabilities when update the item type
  // As capabilities are now dynamic based on the container
  const formValues = formMethods.getValues();
  useUpdateProviderCapabilitiesForItem({
    formData: formValues,
    isSuspended: formValues.isSuspended,
    linkId,
    formMethods,
    side: 'A',
  });
  useUpdateProviderCapabilitiesForItem({
    formData: formValues,
    isSuspended: formValues.isSuspended,
    linkId,
    formMethods,
    side: 'B',
  });

  return {
    ...formMethods,
    formData: formMethods.getValues(),
    handleSubmit: formMethods.handleSubmit(onSubmit),
    loadingState,
    setLoadingState,
    hasChanged,
  };
}
