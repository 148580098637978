import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { Icon, tokens } from '@unitoio/mosaic';

import * as trackingActions from '~/actions/tracking';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import { getIsSyncInProgress, getLinkById } from '~/reducers';
import { Button } from '~/components/Button/Button';
import { Dropdown, DropdownEllipsisButton, DropdownItem } from '~/components/Dropdown/Dropdown';
import * as linkActions from '~/actions/links';
import { getHasTestModeEnabled } from '~/containers/FlowBuilder/hooks/useHasTestModeEnabled';

import { getTestModeTermToUse } from '~/containers/FlowBuilder/hooks/useGetTestModeTerm';
import { DuplicateDropdownItem } from './DuplicateDropdownItem';

const Flex = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  min-width: 10%;
`;

const ItemWrapper = styled.div`
  margin-left: ${tokens.spacing.s3};
  margin-right: ${tokens.spacing.s3};

  > .btn + .btn {
    margin-left: ${tokens.spacing.s2};
  }
`;

class SyncActionsComponent extends Component {
  static propTypes = {
    linkId: PropTypes.string.isRequired,
    link: PropTypes.instanceOf(Map).isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onSyncNow: PropTypes.func.isRequired,
    showActions: PropTypes.bool,
    isSyncInProgress: PropTypes.bool.isRequired,
    turnOffTestMode: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showActions: false,
  };

  getSyncActionButton = (isLinkStateDraft, isSyncInProgress) => {
    const { linkId, onSyncNow } = this.props;

    if (isLinkStateDraft) {
      return (
        <Button
          btnStyle="dark"
          className="resume-button"
          data-testid="dashboard__btn--resume"
          size="xs"
          title="Resume"
          reverse
          type="href"
          to={`${routes.getEditFlowBuilderRoute(linkId)}`}
        >
          Edit draft
        </Button>
      );
    }

    return (
      <Button
        btnStyle="dark"
        className="sync-now-button"
        data-testid="dashboard__btn--syncnow"
        disabled={isSyncInProgress}
        size="xs"
        title="Sync Now"
        reverse
        type="button"
        onClick={onSyncNow}
      >
        Sync now
      </Button>
    );
  };

  render() {
    const { linkId, onDeleteClick, turnOffTestMode, showActions, link, isSyncInProgress } = this.props;

    const hasTestModeEnabled = getHasTestModeEnabled(link);
    const isSuspended = link.get('isSuspended');
    const kind = link.get('kind');
    const linkState = link.get('state');
    const restricted = link.get('restricted', false);
    const testModeTerm = getTestModeTermToUse(link);

    const isLinkStateDraft = linkState === linkTypes.LINK_STATES.DRAFT;

    const showRemoveTestModeButton = hasTestModeEnabled && !isSuspended && !restricted && !isLinkStateDraft;

    return (
      <Flex className="sync-actions">
        <ItemWrapper>
          {kind !== linkTypes.KIND.TASK_SYNC &&
            !isSuspended &&
            this.getSyncActionButton(isLinkStateDraft, isSyncInProgress)}
        </ItemWrapper>

        {showActions && (
          <Dropdown
            alignRight
            btnContent={DropdownEllipsisButton}
            className="dropdown link-item__more-options"
            data-test="link-item__options-dropdown"
            dataTestId={`more-options-dropdown-${linkId}`}
          >
            {restricted && !isLinkStateDraft && (
              <DropdownItem className="disabled">
                <Icon name="lock" kind={Icon.KINDS.SOLID} fixedWidth />
                <small>You will need to contact the author to edit this flow.</small>
              </DropdownItem>
            )}

            {!isSuspended && !restricted && !isLinkStateDraft && (
              <DropdownItem to={routes.getEditFlowBuilderRoute(linkId)} title="Edit this flow">
                <Icon name="pencil" kind={Icon.KINDS.SOLID} fixedWidth /> Edit
              </DropdownItem>
            )}

            {!isLinkStateDraft && <DuplicateDropdownItem linkId={linkId} />}

            {showRemoveTestModeButton && (
              <DropdownItem onClick={turnOffTestMode} onKeyPress={turnOffTestMode} role="button" tabIndex={0}>
                <Icon name="rocket" kind={Icon.KINDS.SOLID} fixedWidth /> Remove "only {testModeTerm} items"
              </DropdownItem>
            )}

            {isLinkStateDraft && (
              <DropdownItem to={`${routes.getEditFlowBuilderRoute(linkId)}`} title="Resume this flow">
                <Icon name="pencil" kind={Icon.KINDS.SOLID} fixedWidth /> Edit draft
              </DropdownItem>
            )}

            {!restricted && (
              <DropdownItem
                data-test="link-item__delete"
                onClick={onDeleteClick}
                onKeyPress={onDeleteClick}
                role="button"
                tabIndex={0}
              >
                <Icon name="trash" kind={Icon.KINDS.SOLID} fixedWidth />
                Delete
              </DropdownItem>
            )}
          </Dropdown>
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const link = getLinkById(state, ownProps.linkId);
  const isSyncInProgress = getIsSyncInProgress(state, ownProps.linkId);
  return {
    link,
    isSyncInProgress,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSyncNow: () => dispatch(linkActions.syncLink(ownProps.linkId)),
  trackEvent: (...params) => dispatch(trackingActions.trackEvent(...params)),
});

export const SyncActions = connect(mapStateToProps, mapDispatchToProps)(SyncActionsComponent);
