import { useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router-dom';

import * as routes from '~/consts/routes';
import { getOrganizationHasFlows, getOrganizationPendingDraftId, getSelectedOrganizationId } from '~/reducers';

import { useGetQueryParamString } from '~/hooks';
import { useIsFlowDraft } from '../../../hooks/useIsFlowDraft';
import { useIsFlowDuplicate } from '~/containers/FlowBuilder/hooks/useIsFlowDuplicate';

/**
 * This hook returns the right URL & Copy to use in the flow builder to handle back navigation
 * based on current route context (guide page) and link type (draft vs active vs duplicate vs workflow link).
 * Returns a null url & copy if the link is the 'first' draft to be created for a specific workspace.
 * */
export function useGetBackNavigationDetails() {
  const isDraftFlow = useIsFlowDraft();
  const organizationId = useSelector(getSelectedOrganizationId);
  const pendingDraftId = useSelector((state) => getOrganizationPendingDraftId(state, organizationId));
  const hasFlows = useSelector((state) => getOrganizationHasFlows(state, organizationId));
  const match = useRouteMatch();
  const queryParamString = useGetQueryParamString();

  const { pageName, workflowId } = match.params;

  if (pageName !== routes.FLOW_BUILDER_PAGES.GUIDE) {
    return {
      url: `${generatePath(match.path, {
        ...match.params,
        pageName: routes.FLOW_BUILDER_PAGES.GUIDE,
      })}${queryParamString}`,
      copy: isDraftFlow ? 'Back to guide' : 'Back to overview',
    };
  }

  // no back navigation to display when user is still creating their first draft (/add)
  // or is on the guide page of their first draft
  if (!hasFlows || pendingDraftId) {
    return { url: null, copy: null };
  }

  // if we're in the guide page of a draft flow, we want to go back to the workflow designer
  if (workflowId) {
    const workflowPath = `${generatePath(
      `${routes.ABSOLUTE_PATHS.WORKFLOWS}/edit/:workflowId`,
      match.params,
    )}${queryParamString}`;
    return { url: workflowPath, copy: 'Back to workflow designer' };
  }

  return { url: `${routes.ABSOLUTE_PATHS.DASHBOARD}${queryParamString}`, copy: 'Back to flows' };
}
